import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  MouseEvent,
  KeyboardEvent,
} from 'react';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { trackRichText } from 'utils/tracking/trackingRichText';
import { useTracking } from 'utils/tracking/hooks';
import classNames from 'classnames';
import { Button, Content, Image } from '@xxxlgroup/hydra-ui-components';
import { config } from '@xxxlgroup/hydra-config';
import createImageUrl from '@xxxlgroup/hydra-utils/image/createImageUrl';
import Link from 'components/WebshopLink';
import styles from 'cms/CmsAdviceComponent/CmsAdviceComponent.scss';
import WithSmartEdit, { WithSmartEditProps } from 'cms/WithSmartEdit/WithSmartEdit';
import { CmsMedia, CmsLink } from 'graphql-types/generated/types-generated';
import { parseHydraImage } from 'utils/imageUtils';

interface CmsAdviceComponentProps extends WithSmartEditProps {
  /** Content of the advice component */
  adviceContent: string;
  /** Image that will be shown in desktop view */
  desktopImage?: CmsMedia;
  /** Headline of the advice component */
  headline: string;
  /** Image that will be shown in mobile view */
  mobileImage?: CmsMedia;
  /** link for the advice component */
  link?: CmsLink;
  /** Headline of the advice, we use it to mart type of the advice */
  tipHeadline: string;
}

const COMPONENT_TYPE = 'CmsAdviceComponent';

const CmsAdviceComponent: FC<CmsAdviceComponentProps> = (props) => {
  const { adviceContent, desktopImage, headline, mobileImage, link, tipHeadline } = props;
  const [isDesktopView, setIsDesktopView] = useState<boolean>(false);
  const [backgroundImage, setBackgroundImage] = useState<CmsMedia | undefined>({});

  const tracking = useTracking(props, COMPONENT_TYPE);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLOrSVGElement> | KeyboardEvent<HTMLOrSVGElement>) => {
      trackRichText(event, tracking);
    },
    [tracking],
  );

  const handleResize = useCallback(() => {
    setBackgroundImage(window.innerWidth < config.breakpoints.md ? mobileImage : desktopImage);
    setIsDesktopView(window.innerWidth >= config.breakpoints.sm);
  }, [desktopImage, mobileImage]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const backgroundSource = useMemo(
    () =>
      createImageUrl(
        {
          filename: backgroundImage?.filename || '',
          hashCode: backgroundImage?.hashCode || backgroundImage?.cdnFilename || '',
          targetFormat: 'png',
        },
        { width: 300 },
      ),
    [backgroundImage],
  );

  return (
    <div className={styles.fullWidth} data-testid="cms.advice">
      <div className={styles.adviceWrapper}>
        {backgroundImage && isDesktopView && (
          <div
            style={{ backgroundImage: `url(${backgroundSource}` }}
            className={classNames(styles.desktopImage, styles.image)}
          />
        )}
        <div
          className={classNames(styles.contentWrapper, {
            [styles.mobileView]: !isDesktopView,
            [styles.fullWidthContent]: !backgroundImage,
          })}
        >
          <div className={styles.tipHeadline}>{tipHeadline}</div>
          <div className={styles.headline}>{headline}</div>
          <Content
            className={styles.content}
            content={adviceContent}
            data-purpose="CmsAdvice.content"
            onClick={handleClick}
          />
          {link && (
            <Link
              href={link.url}
              target={link.target}
              as={<Button variant="primary" />}
              className={styles.link}
              data-purpose="CmsAdvice.link"
            >
              {link.linkName}
            </Link>
          )}
        </div>
        {!isDesktopView && mobileImage && (
          <div className={styles.mobileImageWrapper}>
            <Image
              className={styles.mobileImage}
              height={mobileImage.pictureHeight ?? undefined}
              useSrcSet
              width={300}
              source={parseHydraImage({ ...mobileImage, targetFormat: 'png' }) ?? {}}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default track(tagComponent(COMPONENT_TYPE))(WithSmartEdit(CmsAdviceComponent));
